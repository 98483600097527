(function (win, $) {
    'use strict';
    var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
        common = (fp.common = typeof fp.common !== 'undefined' ? fp.common : {});

    fp.common = (function () {
        var getAncestorNode = function (el, className) {
            var i = 0;
            while (i++ < 10) {
                var parentEl = el.parentElement;
                if (!parentEl) {
                    return null;
                }
                if (parentEl.classList.contains(className)) {
                    return parentEl;
                }
                el = parentEl;
            }
            return null;
        };

        var initNavigation = function () {
            var greaterThanMDscreen = function () {
                return $('#width-detection').css('float') === 'left';
            };

            var closeOpenMenus = function (navEl) {
                // We use the class, expanding-nav to denote a menu that expands in large screen similar to a hamburger
                // Thus we should not close it when we navigate away.
                if (!greaterThanMDscreen() || document.body.classList.contains('hamburger-nav') || document.querySelectorAll('.expanding-nav').length > 0) {
                    return;
                }
                var topLevel = navEl.querySelectorAll('.top-level-link.open');
                for (var i = 0; i < topLevel.length; ++i) {
                    topLevel[i].classList.remove('open');                    
                    topLevel[i].setAttribute('aria-expanded', false);
                }
                var secondary = navEl.querySelectorAll('.secondary.expanded');
                for (i = 0; i < secondary.length; ++i) {
                    secondary[i].classList.remove('expanded');
                }
            };

            // Primary Navigation
            var navEls = document.getElementsByTagName('nav');
            Array.prototype.slice.apply(navEls).forEach(function (navEl) {
                navEl.addEventListener('mouseover', function (e) {
                    closeOpenMenus(navEl);
                });

                navEl.addEventListener('click', function (e) {
                    var tgt = e.target;
                    if (tgt.nodeName.toLowerCase() === 'svg' || tgt.nodeName.toLowerCase() === 'rect') {
                        tgt = getAncestorNode(tgt, 'top-level-link');
                    }

                    if (!tgt || !tgt.classList.contains('top-level-link')) {
                        return;
                    }
                    // In a vertical nav like Amphora & Full course they should be able to click top level links
                    // unless they are on mobile
                    if (tgt.classList.contains('vertical-nav') && greaterThanMDscreen()) {
                        return;
                    }

                    e.preventDefault();
                    if (tgt.classList.contains('open')) {
                        tgt.classList.remove('open');
                        tgt.setAttribute('aria-expanded', false);

                        $(tgt)
                            .siblings('.secondary')
                            .removeClass('expanded');
                    } else {
                        closeOpenMenus(navEl);

                        tgt.classList.add('open');
                        tgt.setAttribute('aria-expanded', true);

                        $(tgt)
                            .siblings('.secondary')
                            .addClass('expanded');
                    }
                });
            });
        };

        var hideErrorBlocks = function () {
            var errorEls = document.querySelectorAll('.error-message, .field-validation-error');
            for (var j = 0; j < errorEls.length; j++) {
                errorEls[j].classList.add('hidden');
            }
            var validationBlockEl = document.querySelector('.validation-summary-valid, .validation-summary-errors');
            if (!validationBlockEl) {
                return;
            }
            validationBlockEl.classList.remove('validation-summary-errors');
            validationBlockEl.classList.add('validation-summary-valid');
            return;
        }

        function scrollAndFocus(formScrollTarget) {
            // Scroll to the target element
            if (formScrollTarget != null) { 
                formScrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });

                // Delay focusing operation until scrolling animation is complete
                setTimeout(function () {
                    formScrollTarget.focus({ preventScroll: true });

                    // Get the current scroll position
                    var currentScrollPosition = window.scrollY || window.pageYOffset;

                    // Calculate the target scroll position with a -125 pixels offset
                    var targetScrollPosition = currentScrollPosition - 125;

                    // Scroll smoothly to the target scroll position
                    window.scrollTo({
                        top: targetScrollPosition,
                        behavior: 'smooth'
                    });
                }, 1000);
            }
        }

        var showValidationBlock = function (errors) {
            var validationBlockEl = document.querySelector('.validation-summary-valid, .validation-summary-errors');
            if (!validationBlockEl) {
                return;
            }

            // Clear existing errors from the list
            var listEl = validationBlockEl.getElementsByTagName('ul')[0];
            while (listEl.firstChild) {
                listEl.removeChild(listEl.firstChild);
            }

            // If there are no validation errors then set it to valid, which hides it
            if (errors.length === 0) {
                validationBlockEl.classList.remove('validation-summary-errors');
                validationBlockEl.classList.add('validation-summary-valid');
                return;
            }

            // If there are errors then append them to the block and show it
            for (var i = 0; i < errors.length; i++) {
                var errorEl = document.createElement('li');
                errorEl.innerText = errors[i].message;
                listEl.appendChild(errorEl);
            }

            validationBlockEl.classList.remove('validation-summary-valid');
            validationBlockEl.classList.add('validation-summary-errors');

            // Scroll to the form error target and focus on it
            var formScrollTarget = document.getElementById('form-error-target');
            scrollAndFocus(formScrollTarget);
        };

        var handleSpecialMessage = function () {
            var showMessage = win.sessionStorage.getItem('hideSpecialMessage') !== 'true';

            if (!showMessage) {
                return;
            }
            // Special message.
            var $specialMessage = $('#special-message');

            if ($specialMessage.length === 0) {
                return;
            }
            var isModal = $specialMessage.is('.modal');
            var now = new Date();

            var startDateString = $specialMessage.data('start-date');
            if (startDateString !== '') {
                var startDate = new Date(startDateString);
                if (now <= startDate) {
                    return;
                }
            }

            // Check the end date.
            var endDateString = $specialMessage.data('end-date');
            if (endDateString !== '') {
                var endDate = new Date(endDateString);
                if (now >= endDate) {
                    return;
                }
            }

            if (!isModal) {
                $specialMessage.css('display', 'block');

                // Handle close button.
                $('#close-special-message').on("click",function () {
                    win.sessionStorage.setItem('hideSpecialMessage', 'true');
                    $specialMessage.slideUp();
                    return false;
                });

                // Set cookie on link
                $('#special-message-link').on("click", function () {
                    win.sessionStorage.setItem('hideSpecialMessage', 'true');
                });
            } else {
                win.setTimeout(function () {
                    $specialMessage
                        .modal("show")
                        .on("shown.bs.modal", function() {
                            $specialMessage.attr('aria-hidden', 'false');
                            $(".close-tab").trigger("focus");
                        })
                        .on("hidden.bs.modal", function() {
                            $specialMessage.attr('aria-hidden', 'true');
                            win.sessionStorage.setItem(
                                "hideSpecialMessage",
                                "true"
                            );
                        });

                    $specialMessage.find('.close-tab, a, button')
                        .on('click', function () {
                            win.sessionStorage.setItem('hideSpecialMessage', 'true');
                        });
                }, 1500);
            }
        };

        var detectBrowserAndOS = function () {

            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
            {
                $('body').addClass('ie-browser');
            }

            if (navigator.appVersion.indexOf("Win") != -1)
            {
                $('body').addClass('os-windows');
            };

            return false;
        };

        return {
            init: function () {
                // Detect if JS is disabled (used in place of Modernizr)
                // If true, the "no-js" on the HTML tag will remain
                // $('html').removeClass('no-js'); //move to the <head> in CustomerSiteLayout.cshtml
                $(window).on("resize", function () {
                    if ($('#width-detection').css('float') === 'left') {
                        // left === greater than @mobile-nav-bp
                        // right === greater than screen-sm
                        // none === less than screen-sm

                        $('.dropdown .secondary').removeAttr('style');
                        $('.top-level-link').removeClass('open');
                        $('.nav-for-small-screen').removeAttr('style');
                        $('body').removeClass('nav-open');
                    }
                });

                $(window).on("scroll", function () {
                    var height = $(window).scrollTop();

                    if (height > 65) {
                        $('body').addClass('scrolled-down');
                    } else {
                        $('body').removeClass('scrolled-down');
                    }
                });

                detectBrowserAndOS();
                initNavigation();
                handleSpecialMessage();

                // begin auto scroll

                $('.top-link').on("click", function () {
                    var elementClicked = $(this).attr('href');
                    var target = $(elementClicked).offset().top;
                    $('html:not(:animated),body:not(:animated)').animate({ scrollTop: target - 0 }, 1000);
                    // Added the following for ADA compliance (CM-7/21/19)
                    target.focus(); // Setting focus
                    if (target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        target.focus(); // Setting focus
                    };
                    return false;
                });
                //2
                $('.page-jump').on("change", function () {
                    if ($(this).val() != '') {
                        $('#page-jump-form')
                            .attr('action', $(this).val())
                            .trigger("submit");
                    }
                });

                // Responsive videos
                //$('.video-container').fitVids();

            },

            initPullMenu: function () {
                var $body = $('body');

                if ($body.hasClass('offcanvas-nav')) {
                    //for templates using offcanvas/pushy nav
                    //additional JS set from pushy.js

                    var $pullClose = $('#js-mobile-nav-button-exit');
                    $pullClose.on('click', function () {
                        //returns focus to hamburger when menu is closed
                        $('#js-mobile-nav-button').trigger("focus");
                    });

                } else {
                    //for templates using standard overlay mobile nav
                    var $pull = $('#js-mobile-nav-button, #js-mobile-nav-button-exit');
                    var $menu = $('.nav-for-small-screen');

                    $pull.on('click', function () {
                        $menu.slideToggle();
                        if ($body.hasClass('nav-open')) {
                            $body.removeClass('nav-open');
                            $(".toggle-navigation").attr('aria-expanded', 'false');
                            $("#main-nav-announcement").text("Main navigation closed");
                            if ($(this).hasClass('exit-navigation')) {
                                //returns focus to hamburger when menu is closed
                                $('#js-mobile-nav-button').trigger("focus");
                            };
                        } else {
                            $body.addClass('nav-open');
                            $(".toggle-navigation").attr('aria-expanded', 'true');
                            $("#main-nav-announcement").text("Main navigation open");
                        }
                    });
                }
            },

            genericHookupForm: function () {
                $('.general-contact-form').on("submit", function (e) {
                    e.preventDefault();
//                    $('.general-contact-form input.submit').attr('value', 'Sending...');
                    $('#form-submit').val('Sending...');

                    var $form = $(e.target);
                    var url = $form.attr('action');

                    var data = $form.serializeArray();
                    // Form version 2 will do a full page redirect on success instead of injecting the message
                    data.push({ name: 'apiVersion', value: 2 });

                    $.ajax({
                        url: url,
                        data: data,
                        method: 'POST'
                    })
                        .then(function (resp) {
                            hideErrorBlocks();
                            if (resp.success) {

                                var thankYouHeading = document.createElement('h2');
                                thankYouHeading.innerText = resp.pageHeading;
                                thankYouHeading.tabIndex = -1;

                                var successMsg = document.createElement('p');
                                successMsg.innerHTML = resp.message;

                                var formEl = document.getElementById('general-form-wrapper');
                                formEl.innerHTML = '';
                                formEl.appendChild(thankYouHeading);
                                formEl.appendChild(successMsg);
                                
                                // Scroll to the form error target and focus on it
                                var formScrollTarget = document.querySelector('#general-form-wrapper h2');
                                scrollAndFocus(formScrollTarget);

                                // Change page title
                                document.title = resp.pageTitle;

                            }
                            else
                            {
                                if (!(resp.errors == null) && (Array.isArray(resp.errors))) {
                                    for (var i = 0; i < resp.errors.length; i++) {
                                        var err = resp.errors[i];
                                        var field = document.getElementById(err.fieldName);
                                        if (!field) {
                                            continue;
                                        }

                                        // show errors from the server
                                        var errMsgEl = field.parentNode.querySelector('.field-validation-error, .field-validation-valid');
                                        if (!errMsgEl) {
                                            errMsgEl = document.createElement('span');
                                            field.parentNode.append(errMsgEl);
                                        } else {
                                            errMsgEl.classList.remove('hidden', 'field-validation-valid');
                                        }
                                        errMsgEl.classList.add('field-validation-error');
                                        errMsgEl.innerText = err.message;
                                    }
                                    showValidationBlock(resp.errors);
                                    //$('.general-contact-form input.button').attr('value', 'Send');
                                    $('#form-submit').val('Send');
                                }
                                else {  // probably validation 2.0 error

                                    showGenericErrorMessage ( 'Sorry, but there was an error in one or more of your entries. Please check your entries and be careful not to insert tags (<>) or HTML in your inputs.');

                                }
                            }
                        })
                        .fail(function (err) {
                            showGenericErrorMessage ('Sorry, but there was an error submitting this form. Please try again or contact us by phone.');
                        })

                        .always(function () {
                            // Always reset the button text back to "Send"
                            //$('.general-contact-form input.button').attr('value', 'Send');
                            $('#form-submit').val('Send');
                        });

                });
                function showGenericErrorMessage(errText) {
//                    $('.general-contact-form input.button').attr('value', 'Send');
                    $('#form-submit').val('Send');
                    var errorEl = document.createElement('p');
                    errorEl.classList.add('validation-summary-errors');
                    errorEl.innerText = errText;
                    document.getElementById('form-error-target').prepend(errorEl);

                    // Scroll to the form error target and focus on it
                    var formScrollTarget = document.getElementById('form-error-target');
                    scrollAndFocus(formScrollTarget);
                };
            },

            showInstagramPhotos: function (tokenUrl, openInNewWindow, invalidTokenUrl) {
                var getTokenAndShowFeed = function () {
                    var key = 'instagram-token';
                    var token = window.sessionStorage ?
                        window.sessionStorage.getItem(key) : 
                        null;
                    showFeed(token);

                    if (!token) {
                        $.get(tokenUrl)
                            .done(function (resp) {
                                window.sessionStorage.setItem(key, resp.token);
                                showFeed(resp.token);
                            });
                    }
                };

                var showFeed = function (token) {
                    $.getJSON('https://graph.instagram.com/me/media?fields=caption,media_url,thumbnail_url,permalink&access_token=' + token, 
                        function (resp) {
                            var photosContainer = document.querySelector('.feed-wrapper');
                            var igLinkEl = document.querySelector('.instagram-link');

                            resp.data.slice(0, 7).forEach(function (img) {                                
                                var linkEl = document.createElement('a');
                                linkEl.className = "instagram-photo";
                                linkEl.href = img.permalink;
                                if (openInNewWindow) {
                                    linkEl.target = 'instagram';
                                }

                                var instaconEl = document.createElement('span');
                                instaconEl.classList.add('icon');
                                instaconEl.classList.add('icon-instagram');
                                instaconEl.setAttribute('aria-hidden', true);
                                linkEl.appendChild(instaconEl);

                                var imgWrapperEl = document.createElement('div');
                                imgWrapperEl.classList.add('img-wrapper');
                                linkEl.appendChild(imgWrapperEl);

                                var imgEl = document.createElement('div');
                                imgEl.setAttribute('role', 'img');
                                imgEl.setAttribute('aria-label', img.caption);
                                imgEl.classList.add('img-holder');
                                var imgSrc = img.thumbnail_url ? img.thumbnail_url : img.media_url;
                                imgEl.style.backgroundImage = "url('" + imgSrc + "')";

                                var pixelImgEl = document.createElement('img');
                                pixelImgEl.src = '/images/pixel.png';
                                pixelImgEl.alt = 'Single pixel image used for sizing';
                                pixelImgEl.setAttribute('aria-hidden', true);
                                pixelImgEl.width = '1';
                                pixelImgEl.height = '1';                                
                                imgEl.appendChild(pixelImgEl);

                                var srSpanEl = document.createElement('span');
                                srSpanEl.className = 'sr-only';
                                srSpanEl.innerText = 'View on Instagram';

                                imgWrapperEl.appendChild(imgEl);
                                imgWrapperEl.appendChild(srSpanEl);

                                photosContainer.insertBefore(linkEl, igLinkEl);
                            })
                        });
                        // .fail(function (err) {
                        //     // If we have an auth exception then lets call our endpoint to send an email to the site owner
                        //     if (err.status === 400 && err.responseJSON.meta && err.responseJSON.meta.error_type === 'OAuthAccessTokenException') {
                        //         fetch(invalidTokenUrl, {
                        //             method: 'POST',
                        //             mode: 'cors'
                        //         });
                        //         return;
                        //     }
                        // });
                };

                getTokenAndShowFeed();
            },
            
            hideOldClosureDates: function () {
                var closureListEls = document.querySelectorAll(".temporary-closures li");

                var now = new Date();
                var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                for (var i = 0; i < closureListEls.length; i++) {
                    var el = closureListEls[i];
                    var splitDate = el.getAttribute("data-date").split('-');
                    var parsedDate = new Date(parseInt(splitDate[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[2]));

                    if (parsedDate < today) {
                        el.style.display = 'none';
                    }
                }
            }
        };
    })();
})(window, $);
